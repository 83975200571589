$primary: #f7727c;
$secondary: #ffccd0;

$enable-shadows: true;
$enable-gradients: true;

@import '../node_modules/bootstrap/scss/bootstrap';

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;700&family=Playfair+Display:ital,wght@0,400;0,700;1,400;1,700&family=Poppins:ital,wght@0,400;0,500;0,700;1,700&display=swap');

@font-face {
  font-family: 'barcelonyregular';
  src: url('https://mydigitalmoment.com/fonts/barcelony-webfont.woff2') format('woff2'), url('https://mydigitalmoment.com/fonts/barcelony-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gistesy';
  src: url('https://mydigitalmoment.com/fonts/Gistesy.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Branding";
  src: url("https://mydigitalmoment.com/fonts/BrandingSFBlack.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Branding";
  src: url("/fonts/BrandingSFMedium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SmallKitchen";
  src: url("/fonts/Small-Kitchen.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

* {
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
}

p,
a,
ol,
ul,
button {
  font-size: 1.25rem;
}

p.h4 {
  font-weight: 400;
}

div[id^=font-picker] {
  display: block !important;
  width: 100% !important;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
  background-color: white !important;
  border-radius: 6px;
}

div[id^=font-picker] .dropdown-button {
  border: 1px solid #ced4da !important;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
  background-color: white !important;
  border-radius: 6px;
}

div[id^=font-picker] ul {
  background-color: white !important;

  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
  background-color: white !important;
  border-radius: 6px;
}

div[id^=font-picker].expanded ul {
  border: 1px solid #ced4da !important;
}

div[id^=font-picker] ul li button.active-font {
  background-color: #f7727c !important;
  color: white !important;
}

#info-list {
  list-style-type: none;
  border: 1px solid #e45f74;
  border-radius: 10px;
  font-size: 1.3rem;
  text-align: center;
}

#info-list li {
  padding-left: 1rem;
  padding-right: 1rem;
}

.gifts-icon {
  font-size: 10rem;
  color: #0abab5;
}

.btn-outline-primary:hover {
  color: #ffffff !important;
}

@media screen and (max-width: 575px) {
  #info-list {
    font-size: 1rem !important;
  }

  p,
  a,
  ol,
  ul,
  button {
    font-size: 1.1rem;
  }
}